import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Layout, SearchResults, Section, SearchBarInput, Link } from 'components';
import { graphql } from 'gatsby';
import { useBlogSearchIndex } from 'hooks';
import * as styles from './search.module.scss';

const Search = (props) => {
  const { location, data } = props;
  const [searchResults, setSearchResults] = useState([]);

  const blogPosts = data.blogSearchIndexData;

  // format queryString
  const parsed = queryString.parse(location.search);
  const { q: query } = parsed;
  const formattedQ = query?.endsWith('/') ? query?.slice(0, -1) : query;

  const { blogIndex, blogIndexLoading } = useBlogSearchIndex({ allPrismicResourcePost: blogPosts });

  // START OF LUNR INDEXING
  useEffect(() => {
    if (formattedQ && blogIndex) {
      const search = blogIndex.search(`${formattedQ}*`).map((post) => post);

      const matchingPosts = search.map((searchResult) => blogPosts.nodes.find((post) => post.uid === searchResult.ref));
      setSearchResults(matchingPosts);
    }
  }, [formattedQ, blogIndex]);

  return (
    <>
      <Layout theme="light">
        <Section className={styles.resourceHeader}>
          <div className={styles.headerTitle}>
            <h1>Search Results for "{formattedQ}"</h1>
          </div>
          <SearchBarInput blogPosts={blogPosts} />
        </Section>
        <Section className={styles.searchPage} containerClassName={styles.container} largeContainer>
          {!blogIndexLoading && <SearchResults posts={searchResults} query={formattedQ} />}
          {searchResults.length === 0 && <p className={styles.noResults}>No results found</p>}
          <div className={styles.buttonsContainer}>
            <Link className={`button primary ${styles.button}`} to="/resources/">
              Back to resources
            </Link>
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default Search;

export const query = graphql`
  query SearchQuery($lang: String!) {
    blogSearchIndexData: allPrismicResourcePost(filter: { lang: { eq: $lang } }) {
      nodes {
        ...BlogSearchIndexFragment
      }
    }
  }
`;
